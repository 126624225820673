"use client";

import {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { Justify, defaultJustifyClassMap } from "apps/website/maps/Flex.map";
import useWindowSize from "apps/website/hooks/useWindowSize";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Icon from "apps/website/components/base/Icon/Icon";
import Text from "apps/website/components/base/Text/Text";

import { tooltipPosition, TTooltipPlacement } from "./Tooltip.map";

export interface ITooltip {
  id?: string;
  icon?: IconSvg;
  buttonName?: string;
  hideButtonName?: boolean;
  body: string | ReactNode;
  justify?: Justify;
  placement?: TTooltipPlacement;
}

export const getToolTipId = (id: string) => `tooltip-${id}`;

const Tooltip: FC<ITooltip> = ({ id, icon, buttonName = "See more?", hideButtonName, body, justify = "center" }) => {

  const { windowSize } = useWindowSize();

  const tooltipBody = useRef<HTMLDivElement>(null);
  const [ tooltipHeight, setTooltipHeight ] = useState("0px");

  useEffect(() => {
    setTooltipHeight(`${tooltipBody?.current?.offsetHeight}px`);
  }, [ windowSize, tooltipBody ]);

  return (
    <div data-component={Tooltip.name} className="relative group z-30" style={{ "--tooltip-height": tooltipHeight } as React.CSSProperties}>
      <div aria-label={buttonName} className={`w-full flex ${defaultJustifyClassMap[tooltipPosition[justify]?.label ?? "default"]}`}>
        <Text
          tag="div"
          size="2xs"
          align={tooltipPosition[justify]?.buttonText}
          className="underline relative"
        >
          { icon && (
            <Icon icon={icon} size="xsmall" className={!hideButtonName ? "mr-4" : ""}/>
          ) }
          { !hideButtonName && (
            <span data-testid="tooltip-button-name">
              { buttonName }
            </span>
          ) }
          <div className="block transition-all ease-in-out duration-300 absolute -bottom-4 left-1/2 -translate-x-1/2 max-h-0 overflow-hidden group-hover:max-h-4 group-focus-within:max-h-4">
            <div className="border-transparent border-b-black border-8"></div>
          </div>
        </Text>
      </div>
      <div className={`will-change-transform absolute bottom-0 transform translate-y-[100%] transition-all ease-in-out duration-300 overflow-hidden invisible group-hover:visible max-h-0 group-hover:max-h-[var(--tooltip-height)] ${tooltipPosition[justify]?.popup}`}>
        <div className="pt-4" ref={tooltipBody}>
          <div
            id={getToolTipId(id ?? "")}
            role="tooltip"
            data-theme="dark"
            className={`max-w-sm p-2 ${themeRootClassMap.dark}`}
          >
            <Text
              tag="div"
              size="2xs"
              align="default"
              className="w-full [&>p]:mb-0"
              data-testid="tooltip-body"
            >
              { typeof body === "string" ? (
                <div dangerouslySetInnerHTML={ { __html: body }}></div>
              ) : body }
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
