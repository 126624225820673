import { IImageProps } from "apps/website/components/base/Image/Image";

export const animations: Record<string, IImageProps> = {
  munchAndSnooze: {
    src: "/images/sections/animated-split/munch-and-snooze.webp",
    width: 1200,
    height: 188,
  },
  chasingYarn: {
    src: "/images/sections/animated-split/cat-chases-yarn.webp",
    width: 1200,
    height: 262,
  },
  hideInBox: {
    src: "/images/sections/animated-split/hide-in-box.webp",
    width: 1200,
    height: 287,
  },
};

export type Animation = keyof typeof animations;
