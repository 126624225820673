import { FC, ReactNode } from "react";

export interface IOverlay {
  isOpen: boolean;
  id: string;
  component: string;
  children: ReactNode;
}

const Overlay: FC<IOverlay> = ({ isOpen, id, component = "Overlay", children }) => (
  <div
    data-component={component}
    id={id}
    aria-hidden={!isOpen}
    className={`z-[60] bg-black bg-opacity-50 top-0 left-0 fixed h-screen w-screen transition-all overflow-y-auto ${isOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}
  >
    { children }
  </div>
);

export default Overlay;
