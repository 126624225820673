import { FC } from "react";

import Icon from "../../base/Icon/Icon";

import { ValidInputInputType, validInputClassMap } from "./ValidInput.map";

interface IValidInput {
  isValid?: boolean;
  input: ValidInputInputType;
}

export const ValidInput: FC<IValidInput> = ({ isValid = false, input }) => (
  <>
    { isValid && (
      <Icon size="xsmall" icon="tickHeavy" className={`absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-green ${validInputClassMap[input]}`} />
    ) }
  </>
);
