import {
  ChangeEvent,
  ReactNode,
  useCallback,
  KeyboardEvent,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react";

import useA11y from "apps/website/hooks/useA11y";
import * as TextMap from "apps/website/components/base/Text/Text.map";
import { WithTestID } from "apps/website/types";

import Label, { TLabelLayout } from "../Label/Label";
import { FontStyle } from "../Label/Label.map";
import Tooltip, { ITooltip, getToolTipId } from "../../feature/Tooltip/Tooltip";
import { ValidInput } from "../ValidInput/ValidInput";

import { AllowedTypes } from "./Input.map";

export interface IInput extends WithTestID {
  type?: AllowedTypes;
  disabled?: boolean;
  name: string;
  value?: string;
  placeholder?: string;
  label: string;
  labelPosition?: TextMap.Align;
  labelStyle?: FontStyle;
  hideLabel?: boolean;
  optional?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onEnter?: () => void;
  onKeyDown?:(event: KeyboardEvent) => void
  onFocus?:() => void;
  onBlur?:() => void;
  component?: string;
  className?: string;
  fontSize?: TextMap.Size;
  tabbable?: boolean;
  children?: ReactNode;
  inputClassName?: string;
  tooltip?: ITooltip;
  autocomplete?: string;
  min?: number;
  max?: number;
  isValid?: boolean;
  labelLayout?: TLabelLayout;
}

export const inputStyles = "box-border border-2 font-display font-sentence w-full h-10 px-4 bg-white rounded-[25px] appearance-none lg:min-h-[50px] placeholder:text-mid-grey";

const Input: ForwardRefExoticComponent<IInput & RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, IInput>(
  ({
    "data-testid": testId,
    labelPosition = "default",
    value,
    name,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    label,
    labelStyle,
    optional,
    type = "text",
    placeholder,
    hideLabel = false,
    onEnter,
    fontSize = "xs",
    disabled = false,
    className,
    component = Input.displayName,
    tabbable = true,
    children,
    inputClassName = "",
    tooltip,
    autocomplete,
    min,
    max,
    isValid,
    labelLayout = "default",
  }, ref) => {
    const { UUID } = useA11y();

    const onLocalKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      onEnterKeyDown(event);
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    const onEnterKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
      if (!onEnter) return;
      if (onEnter && event.key === "Enter") onEnter();
    }, [ onEnter ]);

    return (
      <div
        data-component={component}
        className={className}
        data-theme="form-input"
      >
        { (tooltip && tooltip.placement === "above") && (
          <div className="h-4 pt-2">
            <Tooltip id={ UUID } { ...tooltip } />
          </div>
        ) }
        <Label
          align={labelPosition}
          label={label}
          layout={labelLayout}
          fontStyle={labelStyle}
          hideLabel={hideLabel}
          UUID={UUID}
          optional={optional}
        >
          <div className="relative">
            <input
              data-testid={testId}
              id={UUID}
              type={type}
              min={type === "number" ? min : undefined}
              max={type === "number" ? max : undefined}
              placeholder={placeholder}
              className={`${TextMap.defaultSizeClassMap[fontSize]} ${inputStyles} ${inputClassName}`}
              value={value}
              name={name}
              onKeyDown={onLocalKeyDown}
              tabIndex={tabbable ? 0 : -1}
              disabled={disabled}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              aria-describedby={tooltip ? getToolTipId(UUID) : undefined}
              autoComplete={autocomplete}
              ref={ref}
            />
            <ValidInput isValid={isValid} input="input" />
          </div>
        </Label>
        { children }
        { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
          <div className="h-4 pt-2">
            <Tooltip id={ UUID } { ...tooltip } />
          </div>
        ) }
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
