import { FC, ReactNode } from "react";

import IconButton from "apps/website/components/base/IconButton/IconButton";
import Overlay from "apps/website/components/base/Overlay/Overlay";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import {
  Theme,
  pseudoThemeRootClassMap,
  themeRootClassMap,
} from "apps/website/maps/Theme.map";

import { Size } from "../../layout/Container/Container.map";

import {
  IModalClasses,
  modalBorderRadiusClassMap,
  ModalType,
  modalTypeClassMap,
  TModalBorderRadius,
} from "./Modal.map";

export interface IModal {
  isOpen: boolean;
  onClose(): void;
  id: string;
  component: string;
  modalTitleId: string;
  modalDescriptionId?: string;
  size?: Size;
  hideCloseButton?: boolean;
  children: ReactNode;
  theme?: Theme;
  type?: ModalType;
  borderRadius?: TModalBorderRadius
}

const Modal: FC<IModal> = ({
  isOpen,
  onClose,
  id,
  component,
  modalTitleId,
  modalDescriptionId,
  size,
  hideCloseButton = false,
  theme = "light",
  type = "default",
  borderRadius = "default",
  children,
}) => {
  const computedClasses: IModalClasses = modalTypeClassMap[type];
  return (
    <Overlay component={component} id={id} isOpen={isOpen}>
      { /*
        TODO: dialogs may have inconsistent browser default styles
        they were only supported in IOS in 2022 so for now we will
        use a div with a role - In future <div /> should be replaced
        with <dialog />
      */ }
      <div
        role="alertdialog"
        aria-modal="true"
        aria-labelledby={modalTitleId}
        aria-describedby={modalDescriptionId}
        className={computedClasses.wrapper}
        data-theme={theme}
        onClick={(event) => {
          if (event.target !== event.currentTarget) return;
          event.nativeEvent.stopPropagation();
          onClose();
        }}
      >
        <Container size={size}>
          <Grid
            theme={theme}
            className={`${themeRootClassMap[theme]} ${pseudoThemeRootClassMap[theme]} ${computedClasses.content} ${isOpen ? computedClasses.contentOpen : computedClasses.contentClosed} relative max-h-[90vh] mt-[5vh] ${modalBorderRadiusClassMap[borderRadius]}`}
          >
            <Column>
              { children }
            </Column>
            <>
              { !hideCloseButton && (
                <div className={`absolute top-2 right-2 bg-${theme} h-6`}>
                  <IconButton icon="cross" label="Close modal window" onClick={onClose} />
                </div>
              ) }
            </>
          </Grid>
        </Container>
      </div>
    </Overlay>
  );
};

export default Modal;
