"use client";

import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface IA11y {
  UUID: string;
}

export default function useA11y(): IA11y {
  const [ UUID, setUUID ] = useState<string>();

  useEffect(() => {
    setUUID(uuidv4());
  }, []);

  return {
    UUID: UUID ?? "",
  };
}
