import { useEffect, useState } from "react";

interface IFocusTrap {
  getKeyboardFocusableElements(element: Element): HTMLElement[];
  initFocusTrap(element: string): void;
  destroyFocusTrap(button: string): void;
}

export default function useFocusTrap(): IFocusTrap {

  const [ domElement, setDomElement ] = useState<HTMLElement | null>(null);
  const [ domFocusableElements, setDomFocusableElements ] = useState<HTMLElement[] | null>(null);

  const getKeyboardFocusableElements = (element: Element): HTMLElement[] => {
    if (!element) return [];
    return [
      ...element.querySelectorAll(
        "a[href], button, input, textarea, select, details,[tabindex]:not([tabindex='-1'])",
    ) as any, // eslint-disable-line
    ].filter((el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")) as HTMLElement[];
  };

  const initFocusTrap = (element?: string) => {
    if (element) {
      setDomElement(document.querySelector(`#${element}`) as HTMLElement);
    }
  };

  const destroyFocusTrap = (button?: string) => {
    domElement?.removeEventListener("keydown", addFocusTrapEventListeners);
    if (button) {
      (document?.querySelector(`#${button}`) as HTMLButtonElement)?.focus();
    }
  };

  const addFocusTrapEventListeners = (event: KeyboardEvent): void => {
    const isTabPressed = event.key === "Tab";

    if (!isTabPressed) return;

    if (event.shiftKey) {
      if (document.activeElement === domFocusableElements?.[0] || document.activeElement === domElement) {
        event.preventDefault();
        if (domFocusableElements && domFocusableElements.length && domFocusableElements.length > 0) {
          (domFocusableElements[domFocusableElements.length - 1] as HTMLElement).focus();
        }
      }
    } else if (document.activeElement === domFocusableElements?.[domFocusableElements.length - 1]) {
      event.preventDefault();
      (domFocusableElements[0] as HTMLElement).focus();
    }
  };

  useEffect(() => {
    if (domElement) {
      setDomFocusableElements(getKeyboardFocusableElements(domElement));
    }
  }, [ domElement ]);

  useEffect(() => {
    domElement?.addEventListener("keydown", addFocusTrapEventListeners);
  }, [ domElement, domFocusableElements ]);

  return {
    getKeyboardFocusableElements,
    initFocusTrap,
    destroyFocusTrap,
  };
}
