import { Justify } from "apps/website/maps/Flex.map";

import { Align } from "../../base/Text/Text.map";

export type TTooltipPlacement = "default" | "above";

export type TooltipPositions = {
  [key in Justify]?: ITooltipElements
};

interface ITooltipElements {
  label: Justify;
  buttonText: Align;
  popup: string;
}

export const tooltipPosition: TooltipPositions = {
  center: {
    label: "center",
    buttonText: "center",
    popup: "left-1/2 -translate-x-1/2 ",
  },
  start: {
    label: "start",
    buttonText: "default",
    popup: "left-0",
  },
  end: {
    label: "end",
    buttonText: "right",
    popup: "right-0",
  },
};
