import { useState, useEffect } from "react";

import useA11y from "apps/website/hooks/useA11y";

interface IModal {
  modalId: string;
  modalTitleId: string,
  modalDescriptionId: string,
  modalButtonId: string;
  modalIsOpen: boolean;
  setModalIsOpen(newState: boolean): void;
}

export default function useModal(initialStateIsOpen: boolean): IModal {

  const { UUID } = useA11y();

  const [ modalId, setModalId ] = useState<string>("");
  const [ modalTitleId, setModalTitleId ] = useState<string>("");
  const [ modalDescriptionId, setModalDescriptionId ] = useState<string>("");
  const [ modalButtonId, setModalButtonId ] = useState<string>("");
  const [ modalIsOpen, setModalIsOpen ] = useState(initialStateIsOpen);

  useEffect(() => {
    setModalId(`modal-${UUID}`);
    setModalTitleId(`modal-title-${UUID}`);
    setModalDescriptionId(`modal-description-${UUID}`);
    setModalButtonId(`modal-button-${UUID}`);
  }, []);

  return {
    modalId,
    modalTitleId,
    modalDescriptionId,
    modalIsOpen,
    setModalIsOpen,
    modalButtonId,
  };
}
