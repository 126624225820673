"use client";

import {
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import useWindowSize from "apps/website/hooks/useWindowSize";
import useA11y from "apps/website/hooks/useA11y";
import {
  Breakpoint,
  tailwindBreakpointsMap,
} from "apps/website/maps/Breakpoint.map";
import {
  State,
  stateToBoolean,
  booleanToState,
} from "apps/website/utils/misc/state";
import Text, { IText } from "apps/website/components/base/Text/Text";
import Icon from "apps/website/components/base/Icon/Icon";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { WithTestID } from "apps/website/types";

import SectionAnimatedSplit from "../../section/SectionAnimatedSplit/SectionAnimatedSplit";
import {
  Animation,
} from "../../section/SectionAnimatedSplit/SectionAnimatedSplit.map";

import { BreakpointState, AccordionType } from "./Accordion.map";
import styles from "./Accordion.module.css";

export interface IAccordion extends WithTestID {
  title: string;
  openTitle?: string;
  theme?: Theme;
  state?: State | BreakpointState;
  type?: AccordionType;
  Content: ReactElement;
  bottomAnimation?: Animation;
}

const Accordion: FC<IAccordion> = ({ title, theme = "default", state = "closed", type = "default", Content, openTitle, bottomAnimation, "data-testid": testId }) => {

  const { windowSize, isActiveBreakpointBelow } = useWindowSize();
  const { UUID } = useA11y();

  const accordionBody = useRef<HTMLDivElement>(null);
  const [ accordionBreakpointState, setAccordionBreakpointState ] = useState<BreakpointState>({});
  const [ isAccordionOpen, setIsAccordionOpen ] = useState(false);
  const [ accordionHeight, setAccordionHeight ] = useState("0px");
  const [ headerId, setHeaderId ] = useState<string | undefined>();
  const [ bodyId, setBodyId ] = useState<string | undefined>();
  const [ icon, setIcon ] = useState<IconSvg>("minus");

  const isDefaultTheme = theme === "default";

  const text: Record<AccordionType, Omit<IText, "children">> = {
    default: {
      display: "subtitle",
      size: legacySizeCollectionMap.titleSm,
      align: "default",
    },
    "horizontal-rule": {
      display: "subtitle",
      size: legacySizeCollectionMap.titleSm,
      align: "center",
    },
    underline: {
      display: "subtitle",
      size: legacySizeCollectionMap.titleSm,
      align: "center",
    },
    "full-border": {
      display: "subtitle",
      size: legacySizeCollectionMap.titleSm,
      align: "default",
    },
    footer: {
      display: "title",
      size: "default",
      align: "default",
    },
  };

  const toggleAccordion = () => {
    setAccordionBreakpointState({
      ...accordionBreakpointState,
      [windowSize?.activeBreakpoint]: booleanToState(!isAccordionOpen),
    });
  };

  const initAccordionInitialState = () => {
    const initialState: BreakpointState = {};
    let lastKey = typeof state === "string" ? state : "closed";

    for (const breakpoint in tailwindBreakpointsMap) {
      if (state || (state as BreakpointState)[breakpoint as Breakpoint] || lastKey) {
        const breakpointState = typeof state === "string"
          ? state : (state as BreakpointState)[breakpoint as Breakpoint]
            ? (state as BreakpointState)[breakpoint as Breakpoint]
            : lastKey;
        lastKey = breakpointState ?? "closed";

        initialState[breakpoint as Breakpoint] = breakpointState as State;
      }
    }

    setAccordionBreakpointState(initialState);
  };

  useEffect(() => {
    initAccordionInitialState();
    setHeaderId(`accordion-header-${UUID}`);
    setBodyId(`accordion-body-${UUID}`);
  }, [ UUID ]);

  useEffect(() => {
    setIsAccordionOpen(stateToBoolean(accordionBreakpointState[windowSize?.activeBreakpoint as Breakpoint] ?? "closed"));
  }, [ accordionBreakpointState, windowSize ]);

  useEffect(() => {
    setIcon(isAccordionOpen ? "minus" : "plus");
  }, [ isAccordionOpen ]);

  useEffect(() => {
    setAccordionHeight(`${accordionBody?.current?.offsetHeight}px`);
  }, [ isAccordionOpen, windowSize ]);

  return (
    <div
      data-testid={testId}
      data-component="Accordion"
      className={clsx(
        "w-full",
        [ "default" ].includes(type) && "border-b-2 border-solid",
        themeRootClassMap[theme],
        !isDefaultTheme ? "px-4 mb-2" : "border-black theme-footer:border-light-grey lg:theme-footer:border-none",
        type === "underline" && "flex flex-col",
        type === "full-border" && "border-2 border-solid",
      )}
      data-theme={theme}
      data-state={booleanToState(isAccordionOpen)}
    >
      <div className="order-last">
        <button
          data-testid={`${testId}-toggle`}
          id={headerId}
          className={`d flex items-center w-full py-4 ${[ "default", "footer", "full-border" ].includes(type) ? "justify-between" : "justify-center overflow-hidden"}`}
          onClick={toggleAccordion}
          aria-controls={bodyId}
          aria-expanded={isAccordionOpen}
        >
          <Text
            tag="span"
            display={text[type].display}
            size={text[type].size}
            align={text[type].align}
            className={`${[ "horizontal-rule", "underline" ].includes(type) && "underline"} ${type === "horizontal-rule" && "relative before:-translate-x-[calc(100%+16px)] before:absolute before:top-1/2  before:block before:w-screen before:h-0.5 before:bg-black"}`}
          >
            { (isAccordionOpen && openTitle) ? openTitle : title }
          </Text>
          { ([ "default", "full-border" ].includes(type) || (type === "footer" && isActiveBreakpointBelow("lg"))) && (
            <Icon icon={icon} size="small" />
          ) }
          { ([ "horizontal-rule", "underline" ].includes(type)) && (
            <div className={`${type === "horizontal-rule" && "relative after:ml-4 after:right-0 after:translate-x-[calc(100%+16px)] after:absolute after:top-1/2 after:block after:w-screen after:h-0.5 after:bg-black"}`}>
              <Icon icon="arrowDown" size="xsmall" className={`transition-transform ease-in-out duration-300 ${isAccordionOpen ? "rotate-180" : "rotate-0"}`}/>
            </div>
          ) }
        </button>
      </div>
      <div className={`overflow-hidden transition-all duration-300 ${styles.body}`} style={{ "--accordion-height": accordionHeight } as React.CSSProperties}>
        <div
          id={bodyId}
          ref={accordionBody}
          aria-hidden={!isAccordionOpen}
          aria-labelledby={headerId}
          data-content
        >
          <div data-children>
            { Content }
          </div>
          <Spacer size="lg" />
          { bottomAnimation && (
            <SectionAnimatedSplit animation={bottomAnimation} themeTop={theme} themeBottom={theme}/>
          ) }
        </div>
      </div>
    </div>
  );
};

export default Accordion;
