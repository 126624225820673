export type ModalType = "default" | "slideUp";

export interface IModalClasses {
  wrapper: string;
  content: string;
  contentOpen: string;
  contentClosed: string;
}

export const modalTypeClassMap: Record<ModalType, IModalClasses> = {
  default: {
    wrapper: "w-full h-full flex justify-center items-center pt-12 pb-24 md:py-12",
    content: "overflow-auto",
    contentOpen: "",
    contentClosed: "",
  },
  slideUp: {
    wrapper: "w-full h-full flex justify-center items-end",
    content: "relative before:absolute before:top-0 before:left-1/2 before:w-screen before:h-full before:transform before:-translate-x-1/2 before:z-[-1] overflow-auto duration-500 ease-in-out transition-all pb-[100px]",
    contentOpen: "opacity-100 translate-y-0",
    contentClosed: "opacity-0 translate-y-1/2 delay-300",
  },
};

export const modalBorderRadiusClassMap: Record<string, string> = {
  default: "",
  rounded: "rounded-xl",
} as const;

export type TModalBorderRadius = keyof typeof modalBorderRadiusClassMap;
