"use client";

import { FC, ReactNode, useEffect } from "react";

import useModal from "apps/website/hooks/useModal";
import useFocusTrap from "apps/website/hooks/useFocusTrap";
import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import Modal from "../Modal";

export interface IStoryModalProps {
  id: string;
  buttonText?: string;
  buttonPadding?: Size;
  children: ReactNode;
}

const StoryModal: FC<IStoryModalProps> = ({
  id,
  buttonText,
  buttonPadding = "none",
  children,
}) => {

  const { modalId, modalTitleId, modalButtonId, modalIsOpen, setModalIsOpen } = useModal(false);
  const { initFocusTrap, destroyFocusTrap } = useFocusTrap();

  useEffect(() => {
    if (modalIsOpen) {
      initFocusTrap(modalId);
    } else {
      destroyFocusTrap(modalButtonId);
    }
  }, [ modalIsOpen ]);

  return (
    <>
      <button
        className="inline relative z-10 underline"
        onClick={() => setModalIsOpen(true)}
      >
        { buttonText }
      </button>
      <Spacer size={buttonPadding} />
      <Modal
        id={id}
        component={StoryModal.name}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        modalTitleId={modalTitleId}
        theme={"light"}
        size="sm"
      >
        <div
          data-children
          className="py-2 px-2 md:px-6 flex flex-col items-center"
        >
          { children }
        </div>
      </Modal>
    </>
  );
};

export default StoryModal;
