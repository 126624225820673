import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Image from "apps/website/components/base/Image/Image";
import Container from "apps/website/components/layout/Container/Container";

import { Animation, animations } from "./SectionAnimatedSplit.map";

export interface ISectionAnimatedSplit {
  themeTop: Theme;
  themeBottom: Theme;
  animation: Animation;
}

const SectionAnimatedSplit: FC<ISectionAnimatedSplit> = ({ themeTop = "default", themeBottom = "default", animation }) => (
  <div data-component="SectionAnimatedSplit" className="relative overflow-hidden">
    <div className={`absolute h-1/2 w-full left-0 top-0 ${themeRootClassMap[themeTop]}`}></div>
    <div className={`absolute h-1/2 w-full left-0 bottom-0 ${themeRootClassMap[themeBottom]}`}></div>
    <div className="absolute w-full h-full bg-repeat-x bg-center bg-[url('/images/sections/animated-split/split-line.webp')]"></div>
    <div className="hidden lg:block relative">
      <Container>
        { animations[animation] && (
          <Image image={{
            src: animations[animation].src,
            height: animations[animation].height,
            width: animations[animation].width,
          }}
          alt=""
          />
        ) }
      </Container>
    </div>
    <div className="block lg:hidden relative w-[200vw] transform -translate-x-1/4">
      { animations[animation] && (
        <Image image={{
          src: animations[animation].src,
          height: animations[animation].height,
          width: animations[animation].width,
        }}
        alt=""
        />
      ) }
    </div>
  </div>
);

export default SectionAnimatedSplit;
